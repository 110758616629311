const heb = () => {
    return {
        arias: {
            cover: "כריכה מספר",
            textArea: "טקסט של סיפור, עמוד",
            choose_page_image: "בחר תמונת עמוד",
            close: "סגור",
            preview_book: {
                cover: "דוגמה לדף כריכה",
                image: "דוגמה לדף תמונה",
                text: "דוגמה לדף טקסט",
                mini_image: "דוגמה לתמונה מספר"
            },
            edit_book_cover_selection: "לערוך את בחירת כריכת הספר",
            edit_book_page: "לערוך את מספר עמוד הספר",
            image_number: "תמונה מספר"
        },
        page: "עמוד",
        reset: "חזרה לטקסט המקורי",
        reset_dis: "תוכלו לערוך את הסיפור לאחר התשלום",
        finish: "סיום",
        finished: {
            selfDelivery: "הספר יהיה מוכן לאיסוף עצמי יוחנן הסנדלר 6, קומה 4",
            selfDelivery2: "כפר סבא בעוד {{days_num}} ימי עסקים",
            book: {
                one: "נהדר, עכשיו הכדור אצלנו",
                second: "הספר של {{name}} נשלח לדפוס בארי ",
                third: "ויגיע תוך {{days_num}} ימי עסקים ל:",
            },
            book_self_pick: {
                one: "נהדר!",
                one_1: "עכשיו הכדור אצלנו",
                second: "הספר יהיה מוכן לאיסוף עצמי בכתובת:",
                second_1: "יוחנן הסנדלר 6, קומה 4, כפר סבא",
                third: "זמן ההדפסה הוא עד {{days_num}} ימי עסקים,",
                third_1: " אנחנו נתקשר אליכם כשהספר מוכן לאיסוף"
            },
            mebook_info: {
                one: "כל שאלה ניתן לשלוח למייל"
            },
            pdf: {
                one: "נראה מצויין",
                second: "הספר ישלח לכתובת",
                third: "בדקות הקרובות",
                fourth: "מקווים שנהניתם :)",
            },
            to_watch_status: "מעקב אחר סטטוס ההזמנה",
            status_title: "סטטס",
        },
        general_generating_book_error: {
            error: "אופס, יש לנו בעיה, אנחנו מתקנים"
        },
        already_finished: {
            first: "עריכת הספר הושלמה והספר בדרך אליכם :)",
            second: "יש שאלות? פנו אלינו"
        },
        returned_book: {
            first: "הזמנת הספר בוטלה",
        },
        main_title: "בחרו תמונת כריכה",
        choose_cover: "בחר/י את הכריכה",
        errorCode: {},
        save: "שמור",
        to_buy: "לרכישת הספר והמשך עריכה",
        preview_and_make_pdf: "סיום עריכה הורדת הספר",
        preview_and_make_book: "סיום עריכה והפקת הספר",
        tutorial_title: "ברוכים הבאים לעורך, כאן אנחנו מאפשרים לכם להוסיף את הטאצ׳ האחרון כדי שהספר שלכם יצא מושלם.",
        print: "הפקת הספר",
        tutorial_items: [
            "בכל עמוד בסיפור, תוכלו לעדכן ולשנות את הסיפור כרצונכם ,ולבחור את התמונה הכי מתאימה מתוך ארבעת האפשרויות.",
            "הלכתם רחוק מדי ורוצים לחזור לסיפור המקורי? לחצו על כפתור ״חזרה לטקסט המקורי״ שנו ועדכנו כרצונכם.",
            "תוכלו לשמור את העריכה ולחזור אליה מאוחר יותר. כל עוד לא לחצתם על סיום ושליחה להדפסה השינויים שלכם שמורים אצלנו.",
            "כשסיימתם, לחצו על \"סיום עריכה ושליחה\" כדי שנוכל להפיק את הספר האישי שלכם."
        ],
        end_process: {
            main_text: "מדהים! בחר/י את הכריכה!",
            buttonText: "למעבר לתשלום",
            choose_cover_pdf: "ספר דיגיטלי",
            choose_cover_hard: "ספר מודפס",
            p_description_hard: "ספר מודפס בכריכה קשה והדפסה איכותית בדפוס בארי, שידהים את כל מי שיקבל אותו!",
            p_description_pdf: "ספר אלקטרוני דיגיטלי באיכות גבוהה בפורמט PDF, מעוצב להפליא וניתן לקריאה מכל מקום ובכל זמן.",
            price_pdf: "",
            price_book: "+ משלוח",
            free_delivery: "משלוח חינם",
            price_book_sub: "שליח עד הבית"
        },
        loading_texts: {
            text1: "תחזיקו חזק, הקסם שלנו מתחיל לעבוד",
            subtext1: "רק כמה רגעים קוסמיים ואנחנו שם",
            text2: "הכנסנו את הספר לתנור",
            subtext2: "ההרפתקה האישית שלך בדרך..",
            text3: "יוצרים את הסיפור שלך",
            subtext3: "הספר שלך בדרך אלייך!",
            text4: "מפזרים אבקת קסמים",
            subtext4: "ההרפתקה האישית שלך בדרך..",
            text5: "בקרוב תקבלו ספר מופלא",
            subtext5: "אנחנו מכינים סיפור רק בשבילך!",
        },
        choose: "בחירה",
        tutorial_checkbox: "אל תציג שוב",
        terms: "אני מאשר/ת את ",
        terms_part_2: "תנאי השימוש",
        terms_link: "https://mebook.ai/%d7%9e%d7%93%d7%99%d7%a0%d7%99%d7%95%d7%aa-%d7%a8%d7%9b%d7%99%d7%a9%d7%95%d7%aa-%d7%95%d7%94%d7%97%d7%96%d7%a8%d7%95%d7%aa/",
        click_me: "לחץ על הספר",
        tutorial_button_text: "התחל",
        terms_modal_h: "הסכם זה מתאר את התנאים וההגבלות המסדירים רכישות שבוצעו דרך האתר והאפליקציות של MeBook. זה גם מספק פרטים לגבי מדיניות ההחזרות וההחזרים שלנו.",
        terms_modal: [
            {
                title: "ביצוע הזמנות",
                p: "ניתן לבצע הזמנות של ספרי ילדים מותאמים אישית דרך האתר שלנו או אפליקציות מיועדות. כל ההזמנות מחייבות שליחת פרטים על ילדך יחד עם פרטי תשלום וכתובת משלוח. על ידי ביצוע הזמנה, אתה מאשר שכל המידע שנמסר הוא מדויק."
            },
            {
                title: "תמחור ותשלום",
                p: "המחירים המוצגים כוללים את כל המיסים וההיטלים הרלוונטיים. התשלום חייב להתבצע במלואו בעת הרכישה באמצעות כרטיס אשראי או כל אפשרות תשלום אחרת העומדת לרשותך. MeBook שומרת לעצמה את הזכות לעדכן מחירים בכל עת."
            },
            {
                title: "אישור ועיבוד הזמנה",
                p: "מייל אישור הזמנה עם פרטי הרכישה ישלח עם סיום ביצוע התשלום. יצירת והדפסת הספר יתחילו ברגע שהתשלום יעובד בהצלחה. זמני אספקה משוערים יפורסמו באישור ההזמנה."
            },
            {
                title: "הובלה ומשלוח",
                p: "אנו נשלח את הספר שלך לכתובת שצוינה באתר. זמני אספקה עשויים להשתנות בהתאם למיקומך. MeBook לא יכולה לשאת באחריות לעיכובים שנגרמו על ידי שותפי אספקה של צד שלישי."
            },
            {
                title: "החזרות וזיכויים",
                p: "אם הספר שלך נפגם במהלך המשלוח או שונה באופן משמעותי מהתצוגה המקדימה שסופקה, אנא צור איתנו קשר תוך 7 ימים מהמסירה כדי ליזום ביצוע החזר. אנו נעריך את הנזק או הפער הנטען, אנחנו עשויים לבקש ראיות מצולמות." +
                    "אם יאושר, תקבל החזר על מלוא מחיר הרכישה לאחר שהספר יוחזר למשרדים שלנו. עלויות משלוח חזרה יוחזרו אם סיבת ההחזרה נובעת מנזק או טעויות מצידנו."
            },
            {
                title: "ביטול או שינוי הזמנות",
                p: "בהתחשב באופי האישי של הספרים שלנו, לא ניתן לבטל או לשנות הזמנות לאחר תחילת יצירת הספר. אנא עיין בקפידה בפרטי ההזמנה לפני ביצוע ההזמנה."
            },
            {
                title: "הגבלת אחריות",
                p: "MeBook לא תישא באחריות לכל נזק עקיף, מקרי או תוצאתי הנובע מרכישה או שימוש במוצרים שלנו. האחריות המרבית שלנו מוגבלת למחיר הרכישה של הספר."
            },
            {
                title: "צור קשר",
                p: "אם יש לך שאלות או חששות לגבי ההזמנה שלך, אנא צור איתנו קשר בכתובת info@mebook.ai. אנו מתחייבים לחוויית הזמנה מהנה וקלה ולשביעות רצונך המלא."
            }
        ],
        tutorial_modal: {
            cov: "מתחילים עם בחירת הכריכה שהכי אהבתם לספר",
            items: "עכשיו הגיע הזמן לבחור תמונה מתאימה עבור כל עמוד ולהפוך את הילד שלכם לכוכב הסיפור",
            texts: "ניתן לערוך ולשנות את הטקסט בכל עמוד כרצונכם וליצור סיפור אישי לחלוטין",
            reset: "אם הכתיבה הסתבכה לכם ותרצו לחזור לניסוח המקורי, פשוט לחצו על הכפתור \"חזרה לטקסט המקורי\"",
            save: "שימו לב - ניתן לשמור את העריכה ולחזור אליה במועד מאוחר יותר",
            finish: "כשסיימתם, פשוט לחצו על הכפתור 'סיום עריכה' ונוכל להתחיל בהפקת הספר  עבורכם",
            skip: "דלג",
            next: "הבא",
            back: "קודם",
            last: "אחרון"
        },
        tutorial_modal_preview: {
            cov: "בואו נתחיל בבחירת כריכה לספר שלכם",
            items: "להתאמה מושלמת - תוכלו לבחור תמונה עבור כל עמוד בסיפור",
            texts: "אחרי הרכישה, תוכלו לערוך להוסיף ולשנות את הטקסט בספר וליצור סיפור אישי ומותאם",
            reset: "אם הכתיבה הסתבכה לכם ותרצו לחזור לניסוח המקורי, פשוט לחצו על הכפתור \"חזרה לטקסט המקורי\"",
            finish: "להמשך רכישה ולצפייה בספר והמשך עריכה",
        },
        preview: {
            image_blurred_text: "התמונה תוצג לאחר רכישת הספר"
        },
        after_book_sent: {
            title: "שנייה לפני שאנחנו מפיקים את הספר שלכם",
            text1: "שימו לב שלאחר הפקת הספר, לא יהיה ניתן לבצע שינויים.",
            text2: "אנא וודאו כי שם הילד/ה הוא",
            text3: "ואין טעויות כתיב בסיפור במידה ושיניתם את הסיפור.",
            text4: "וכי התמונות המסומנות הם אלה שהכי אהבתם מתוך הרביעייה האפשרית.",
            button: "אישור",
            edited_cover: "המערכת זיהתה ששיניתם את כותרת הספר, אנא אשרו שהשינוי בכותרת לטעמכם ומאושר להדפסה."
        },
        back_to_website: "חזרה לאתר",
        past_books: "ספרים שלא הושלמו",
        more_images_editor: "עוד לא וואו?",
        more_images_editor_2: "צרו תמונות נוספות!",
        more_images_editor_warn: "אם זה לא וואו, זה לא מיבוק!\n" +
            "אנחנו מזמינים אתכם להפעיל שוב את הקסם וליצור 4 תמונות חדשות.\n" +
            "שימו ❤️\n" +
            "האפשרות קיימת עד 4 פעמים בכל ספר.",
        more_images_editor_warn_left: "נשארו עוד",
        more_images_editor_warn_left_1: "ניסיונות",
        more_images_button_warn: "אישור",
        more_data_prepearing: "ברגע זה אנחנו עושים קסמים,\n" +
            "התמונה תופיע בעוד כמה רגעים.",
        validation_popup: {
            confirm: "מעולה ,רוצה להמשיך!",
            cancel: "אנסה שוב",
            heading: "שנייה לפני שממשיכים , הנה התמונה שהעליתם והדמות הקסומה שיצרנו עבורכם",
            confirm_message: "Are you happy with this image as the basis for your personalized book?",
            warning_title: "חשוב לדעת:",
            warning: "אחרי התשלום, לא נוכל לשנות את התמונה המקורית. כל האיורים בספר האישי שלכם יתבססו על התמונה הזו.",
            original: "התמונה שהעליתם",
            mebook: "הדמות שמיבוק יצרה"
        },
        edit_text_validation: {
            text_is_too_big: "הטקסט גדול מדי",
            errors_of_pages: "ישנם שגיאות בעמודים",
            errors_of_page: "ישנם שגיאות בעמוד"
        },
        queue_text: "בתור ליצירה:",
        title_editor_text: {
            f_size: "גודל הטקסט",
            f_color: "צבע הטקסט",
            hex_color: "קוד צבע",
            preview: "תצוגה מקדימה",
            save: "שמירה והמשך",
            goback: "חזרה לעריכת הכותרת",
            preview_mode: "תצוגה מקדימה",
            edit_text: "ערכו את כותרת הספר",
            reset: "איפוס",
        },

        consent_popup:{
            title:'שנייה לפני שאנחנו מפיקים את הספר שלכם',
            warning:'שימו לב שלאחר הפקת הספר, לא יהיה ניתן לבצע שינויים.',
            buttons:{
                back:'חזרה לעריכה',

                continue:'המשך הפקת הספר'
            },
            consent_box:{
                title:'חריגות אפשריות לדוגמא:',
                consent:'מאשרים שהתמונות בספר נבדקו וניתן להפיק את הספר'
            },
            instructions:(name)=>([
                {
                    title:<p>אנא וודאו כי שם הילד/ה הוא <strong>{name}</strong> ואין טעויות כתיב בסיפור במידה ושיניתם את הסיפור.</p>
                },
                {
                    title:<p><span className="pikl">בינה מלאכותית מייצרת קסמים, אך יכולה גם לייצר טעויות לפעמים</span>
                        , אנא וודאו כי התמונות שבחרתם ללא חריגויות באיברים, ושהן אלה שהכי אהבתם מתוך הרביעייה האפשרית לכל עמוד בספר.</p>
                }
            ])
        }

    };
};

export default heb;
