import React, {useContext, useEffect, useState} from 'react';
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router";
import {MainContext} from "../ContextState";
import {getBook} from "../ApiCalls";
import LogRocket from "logrocket";
import {APP_SETTINGS} from "../settings";
import Loader from "../common/Loader";
import Header from "./editor/components/Header";
import {setClientIdCookie} from "../common/cookie";

const MainWrapperStyled = styled.div`
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const Wrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || 'he';
    const bookId = queryParameters.get("bookId");
    const clientId = queryParameters.get("clientId");

    const {bookDataContext} = useContext(MainContext);
    const [bookData, s_bookData] = bookDataContext;

    const [pageValid, s_pageValid] = useState(false);

    const goTo = (url) => {
        navigate(`${url}${location.search}`);
    };

    useEffect(() => {
        if (lang === 'he') {
            document.body.style.direction = 'rtl';
        } else {
            document.body.style.direction = 'ltr';
        }
    }, [lang]);

    useEffect(() => {
        if (clientId) {
            setClientIdCookie(clientId);
        }
    }, []);

    useEffect(() => {
        if (!bookData && bookId) {
            getBook(bookId).then(data => {
                s_bookData(data);

                LogRocket.identify(bookId, {
                    email: data.endUserEmail
                });

                switch (data.status) {
                    case APP_SETTINGS.bookStatus.inProcess:
                    case APP_SETTINGS.bookStatus.NOT_STARTED:
                        if (location.pathname !== APP_SETTINGS.pages.preview) {
                            goTo(APP_SETTINGS.pages.preview);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                    case APP_SETTINGS.bookStatus.pendingPayment:
                        if (location.pathname !== APP_SETTINGS.pages.preview) {
                            goTo(APP_SETTINGS.pages.preview);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                    case APP_SETTINGS.bookStatus.approved:
                    case APP_SETTINGS.bookStatus.GENERATING_PRINT_FILES:
                    case APP_SETTINGS.bookStatus.PRINT_FILES_GENERATED:
                    case APP_SETTINGS.bookStatus.GENERATING_PRINT_FILES_FAILED:
                    case APP_SETTINGS.bookStatus.CREATION_FAILED:
                    case APP_SETTINGS.bookStatus.COMPLETION_FAILED:
                        const url = `https://show.mebook.ai?bookId=${bookId}&language=${lang}`;
                        window.location.href = url;
                        // if (location.pathname !== APP_SETTINGS.pages.approved) {
                        //     goTo(APP_SETTINGS.pages.approved);
                        // } else {
                        //     s_pageValid(true);
                        // }
                        break;
                    case APP_SETTINGS.bookStatus.returned:
                        if (location.pathname !== APP_SETTINGS.pages.returned) {
                            goTo(APP_SETTINGS.pages.returned);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                    case APP_SETTINGS.bookStatus.pendingUserApproval:
                        if (location.pathname !== APP_SETTINGS.pages.edit) {
                            goTo(APP_SETTINGS.pages.edit);
                        } else {
                            s_pageValid(true);
                        }
                        break;
                }
            }).catch(e => {
                console.log('errrrar', e);
            });
        } else {
            s_pageValid(true);
        }
    }, [location.pathname]);

    let classes = "";
    if (lang === 'he') {
        classes = 'lang-he';
    }

    let loading = (bookId && !bookData) || !pageValid;
    if (!bookId) {
        loading = true;
    }

    return (
        <MainWrapperStyled className={classes}>
            {loading && (
                <Loader size={200}/>
            )}
            {!loading && (
                <>
                    <Header/>
                    <Outlet/>
                </>
            )}
        </MainWrapperStyled>
    );
};

export default Wrapper;
