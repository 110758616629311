import './styles/normalize.css';
import './styles/animation.css';
import './styles/fonts.css';
import 'react-tooltip/dist/react-tooltip.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {MainContextProvider} from "./ContextState";
import Wrapper from "./pages/Wrapper";
import * as Sentry from "@sentry/react";
import LogRocket from 'logrocket';
import {APP_SETTINGS} from "./settings";
import EditorPage from "./pages/editor/EditorPage";
import PreviewPage from "./pages/preview/PreviewPage";
import ProgressPage from "./pages/progress/ProgressPage";
import BooksIsApprovedPage from "./pages/approved/BooksIsApprovedPage";
import Resizer from "./common/Resizer";
import BooksIsReturnedPage from "./pages/returned/BooksIsReturnedPage";

if (process.env.NODE_ENV === "production") {
    LogRocket.init('uamlxw/editor-prod-whbbz');

    Sentry.init({
        dsn: "https://ab0cfec146f51be5071ddd5f9ae7efd9@o4506189547044864.ingest.sentry.io/4506189555564544",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", "mebook"],
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

function App() {
    return (
        <MainContextProvider>
            <div>
                <Resizer>
                    <Router>
                        <Routes>
                            <Route element={<Wrapper/>}>
                                <Route path='/' element={<div></div>}/>
                                <Route path={APP_SETTINGS.pages.edit} element={<EditorPage/>}/>
                                <Route path={APP_SETTINGS.pages.preview} element={<PreviewPage/>}/>
                                <Route path={APP_SETTINGS.pages.progress} element={<ProgressPage/>}/>
                                <Route path={APP_SETTINGS.pages.approved} element={<BooksIsApprovedPage/>}/>
                                <Route path={APP_SETTINGS.pages.returned} element={<BooksIsReturnedPage/>}/>
                            </Route>
                        </Routes>
                    </Router>
                </Resizer>
            </div>
        </MainContextProvider>
    );
}

export default App;
