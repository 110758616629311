import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CustomCheckbox } from "./CheckboxContainer";

const ConsentContainer = styled.div`
    background-color: #f7ede2;
    border: 1px solid #FFA500;
    border-radius: 1rem;
    padding: 15px;
    margin: 10px 0;

    width: 100%;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        margin: 0;
        padding: 5px 10px;
    }
`;

const Title = styled.h2`
    color: #f7921e;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 1.2rem !important;
    }
`;

const ImageGrid = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    width: 100%;
    gap: 10px;
    position: relative;
    margin-bottom: 15px;

    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-bottom: 5px;

    &::-webkit-scrollbar {
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #fbaf3b;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #fbaf3b;
    }

    @media (max-width: 768px) {
        margin: 0px 0 5px 0 ;
    }

`;

const Image = styled.div`
    width: 4rem;
    height: auto;

    aspect-ratio: 1;
    background-color: ${ props => props.src ? "transparent" : "#FFD700" };
    background-image: ${ props => props.src ? `url(${ props.src })` : "none" };
    background-size: cover;
    flex: 0 1 auto;
    flex-shrink: 0;
    flex-grow: 1;

    background-position: center;
    border-radius: 5px;
`;

const ConsentRow = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const GradientOverlay = styled.div`
    position: sticky;
    top: 0;
    bottom: 0;
    width: 64px;
    pointer-events: none;
    transition: opacity 0.3s ease;
    z-index: 1;
    opacity: ${ props => props.show ? 1 : 0 };
`;
const LeftGradient = styled(GradientOverlay)`
    left: 0;
    background: linear-gradient(to right, #f7ede2, transparent);
`;

const RightGradient = styled(GradientOverlay)`
    right: 0;
    background: linear-gradient(to left, #f7ede2, transparent);
`;

const ConsentText = styled.label`
    font-size: 14px;
    color: #333;

    @media (max-width: 768px) {

        font-size: 12px;

    }
`;

const ConsentBox = ({ onCheck, checked, langData, lang }) => {
    const images = [
        "https://print.mebook.ai/static/frame/anomaly/mal1.png",
        "https://print.mebook.ai/static/frame/anomaly/mal2.png",
        "https://print.mebook.ai/static/frame/anomaly/mal3.png",
        "https://print.mebook.ai/static/frame/anomaly/mal4.png",
        "https://print.mebook.ai/static/frame/anomaly/mal5.png",
        "https://print.mebook.ai/static/frame/anomaly/mal6.png",
        "https://print.mebook.ai/static/frame/anomaly/mal7.png",
        "https://print.mebook.ai/static/frame/anomaly/mal8.png",
        "https://print.mebook.ai/static/frame/anomaly/mal9.png"
    ];
    const [showLeftGradient, setShowLeftGradient] = useState(false);
    const [showRightGradient, setShowRightGradient] = useState(true);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainerRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
                setShowLeftGradient(scrollLeft > 0);
                setShowRightGradient(scrollLeft < scrollWidth - clientWidth - 1);
            }
        };

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", handleScroll);
            handleScroll(); // Initial check
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <ConsentContainer className="consent-container">
            <Title>{ langData?.consent_popup?.consent_box?.title }</Title>
            <ImageGrid ref={ scrollContainerRef }>
                <LeftGradient show={ showLeftGradient }/>
                { images.map((src, index) => (
                    <Image key={ index } src={ src }/>
                )) }

                <RightGradient show={ showRightGradient }/>
            </ImageGrid>
            <ConsentRow>
                <CustomCheckbox lang={ lang } checked={ checked } onCheck={ (e) => {
                    onCheck(e);
                } }></CustomCheckbox>
                <ConsentText htmlFor="consent">
                    { langData?.consent_popup?.consent_box?.consent }
                </ConsentText>
            </ConsentRow>
        </ConsentContainer>
    );
};

export default ConsentBox;
