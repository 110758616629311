import domtoimage from "dom-to-image";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import PageItemEdit from "../editor/components/PageItemEdit";
import Loader from "../../common/Loader";
import Modal from "../../components/modal/Modal";
import PreviewBookAndPay from "./components/PreviewBookAndPay";
import BookCoverPrev from "../editor/components/book/BookCoverPrev";
import styled from "styled-components";
import { MainContext } from "../../ContextState";
import {
    PreviewActionsStyled, EditorPageStyled, NoBookIdStyled, PagesWrapperStyled
} from "../../styles/EditPreivewStyles";
import { createCover, getBook, getBookJob, getBookPosition, getConfig, getPrebooks, setSelectCover } from "../../ApiCalls";
import { ButtonStyled, ButtonStyledV2 } from "../../styles/CommonStyled";
import { createNewUrl, getParams, transformImage } from "../../common/utils";
import { chooseLang } from "../../common/chooseLang";
import CoverMediaLoader from "../../components/media/CoverMediaLoader";
import RecentPrebooks from "../../components/RecentPrebooks";
import { unregister, register } from "../../common/socket";
import { PAYMENT_URL } from "../../consts";
import ValidationPopup from "./components/ValidationPopup";
import { AnimatePresence, motion } from "framer-motion";

export const ReadyImagesCoverStyled = styled.div`
    border: 4px solid transparent;
    transition: border 0.3s ease;
    border-radius: 9px;
    box-sizing: border-box;

    &.selected {
        border: 4px solid #FFC43D;
    }

    &:focus {
        outline: 1px solid transparent;
    }

    &.preview {
        cursor: default !important;
    }
`;
const GeneraeMoreCoversStyled = styled.div`
    margin-bottom: 40px;

    @media (max-width: 460px) {
        margin-bottom: 20px;
    }
`;


export const PoloirzsddStyled = styled.div`
    position: fixed;
    //position: absolute;
    width: 700px;
    height: 700px;
    left: ${ props => (props.index + 1) * 4000 }px;
`;

export const CoverPicsStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 10px 0;
    flex-wrap: wrap;
    min-height: 264px;
    position: relative;

    .coverImage {
        margin: 0 15px;
    }

    .coverImage.fs .poki-image-0 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    .coverImage.fs .poki-image-1 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: -100%;
    }

    .coverImage.fs .poki-image-2 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: -100%;
        left: 0;
    }

    .coverImage.fs .poki-image-3 {
        width: 200%;
        height: 200%;
        object-fit: cover;
        position: absolute;
        top: -100%;
        left: -100%;
    }

    .coverImage.bookIsReady {
        cursor: pointer;
    }

    img {
        width: 250px;
        height: 250px;
        border-radius: 15px;
    }

    @media (max-width: 767px) {
        flex-wrap: wrap;

        .coverImage {
            width: 45%;
            margin: 5px;
        }

        img {
            width: 100%;
            height: 150px;
        }
    }

    @media (max-width: 480px) {
        display: flex;
        flex-wrap: wrap;
        gap: 3%;
        margin: 20px 0 10px 0;

        .coverImage {
            width: 100%;
            margin: 0;
            flex: 1 1 calc(50% - 3%);
        }

        .coverImage.fs {
            width: 100%;
            height: 100%;
        }
    }
`;

const PreviewPage = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const bookId = queryParameters.get("bookId");
    const tutorialModal = localStorage.getItem("tutorialModePreview");

    const coversRef = useRef(null);
    const wrapperRef = useRef(null);

    const { bookDataContext, configContext, parentDataContext } = useContext(MainContext);
    const [parentData] = parentDataContext;
    const [bookData, s_bookData] = bookDataContext;
    const [config, s_config] = configContext;


    const [loadingNow, setLoading] = useState("");
    const [loadingButtons, s_loadingButtons] = useState("");
    const [, s_openTutorialModal] = useState(false);
    const [imageDataLen, s_imageDataLen] = useState(1);

    const [openPreviewBookModal, s_openPreviewBookModal] = useState(false);
    const [closePreviewBookModalForce, s_closePreviewBookModalForce] = useState(false);
    const [payView, s_payView] = useState(null);

    const [recentPrebooks, s_recentPrebooks] = useState([]);

    const [selectedBookCoverImage, s_selectedBookCoverImage] = useState("");
    const [textData, s_textData] = useState([]);
    const [imagesData, s_imagesData] = useState([]);

    const [height, s_height] = useState(0);

    const [jobsStatuses, s_jobsStatuses] = useState({});

    const langData = chooseLang(lang);

    const [covList, s_covList] = useState([1, 2, 3, 4]);
    const [bookStatus, s_bookStatus] = useState({});

    const prebookPages = bookData?.prebookPages || [];

    const eventName = `progress/${ bookId }`;
    // const eventNamePositions = `positions/${ bookId }`;

    useEffect(() => {
        document.documentElement.lang = lang;
        document.title = langData.main_title;
    }, [lang]);

    useEffect(() => {
        register(eventName);
        window.addEventListener(eventName, onData);

        return () => {
            unregister(eventName);
            window.removeEventListener(eventName, onData);
        };
    }, []);

    useEffect(() => {
        if (!tutorialModal && bookData && bookId) {
            setTimeout(() => {
                s_openTutorialModal(true);
            }, 1000);
        }
    }, [bookData]);

    const loading = loadingNow;
    const noBookId = !bookId;
    const showData = !loading && bookId && bookData;
    const coverIsReady = bookData?.frontCoverData?.images?.length;

    const showButtonPay = coverIsReady && showData && imageDataLen === bookData?.prebookPages?.length;

    useEffect(() => {
        const intervalId = setInterval(() => {
            getMoreBookData(bookId);
        }, 10000);

        if (showButtonPay) {
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [showButtonPay]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            dataPositionsHandle();
        }, 2000);

        if (showButtonPay) {
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [showButtonPay]);

    useEffect(() => {
        if (wrapperRef?.current) {
            s_height(wrapperRef.current.offsetWidth);
        }
    }, [wrapperRef?.current, bookData]);

    useEffect(() => {
        let numberOfPagesWithImages = 0;
        bookData?.bookPagesData?.forEach(pp => {
            if (pp.images?.length) {
                numberOfPagesWithImages++;
            }
        });
        if (bookData?.frontCoverData?.images?.length) {
            numberOfPagesWithImages++;
        }
        s_imageDataLen(numberOfPagesWithImages);
    }, []);

    const dataPositionsHandle = async () => {
        const positions = await getBookPosition(bookId);

        s_bookStatus(pre => {
            const tempData = { ...pre };

            positions.forEach(pp => {
                const pageIndex = pp.bookPageIndex?.toString();
                const bookD = tempData[pageIndex];
                if(!bookD) {
                    tempData[pageIndex] = {
                        pageIndex: pp.bookPageIndex, progress: 0, jobId: pp.jobId, position: null
                    };
                }
                if (bookD) {
                    bookD.position = pp.position;
                }
            });
            return tempData;
        });
    };

    const onPositionData = (socketData) => {
        const { detail } = socketData;

        s_bookStatus(pre => {
            const tempData = { ...pre };
            const tempDataAsArray = Object.values(tempData);
            const detailsAsArray = Object.values(detail);

            tempDataAsArray.forEach(pp => {
                const detailItem = detailsAsArray.find(item => item.jobId === pp.jobId);
                if (detailItem) {
                    pp.position = detailItem.position;
                }
            });
            return tempData;
        });
    };

    const onData = (socketData) => {
        const { bookPageIndex, bookJobPositions, progress, status, jobId } = socketData.detail;

        s_bookStatus(pre => {
            const tempData = { ...pre };
            const pageIndex = bookPageIndex?.toString();
            const bookD = pre[pageIndex];

            const prog = bookD?.progress > progress ? bookD?.progress : progress;

            if (status === "done") {
                setTimeout(() => {
                    getMoreBookData(bookId);
                }, 2000);
            }

            tempData[pageIndex] = {
                pageIndex: bookPageIndex, positions: bookJobPositions, progress: prog, status, jobId, position: bookD?.position || null
            };
            return tempData;
        });
    };

    useEffect(() => {
        if (bookData.endUserPhone) {
            getPrebooks(bookData.endUserPhone).then(data => {
                const filteredBooks = data.filter(pp => pp._id !== bookData._id);
                s_recentPrebooks(filteredBooks);
            });
        }
    }, []);

    const prepareBookData = (dataParam) => {
        const pp = dataParam || bookData;
        if (pp && bookId) {
            const data = pp;

            const tempTextData = [];
            const tempImagesData = [...imagesData];
            const isSavedData = data?.approvedData?.length;

            if (isSavedData) {
                data?.approvedData?.forEach((item, idx) => {
                    if (item.pageIndex > -1) {
                        tempTextData.push(item.text);
                        tempImagesData.push(item.selectedImageUrl);
                    }
                });
            } else {
                data?.bookPagesData?.forEach((item, idx) => {
                    tempTextData[idx] = item.text;
                });

                s_imagesData(pre => {
                    const tempImagesData = [...pre];
                    data?.bookPagesData?.forEach((item, idx) => {
                        tempTextData[idx] = item.text;

                        if (!pre[idx]) {
                            if (item.us_u1) {
                                tempImagesData[idx] = item.us_u1;
                            } else {
                                tempImagesData[idx] = item.images?.[0];
                            }
                        }
                    });
                    return tempImagesData;
                });
            }

            s_textData(tempTextData);
            if (isSavedData) {
                const cover = data.approvedData[0];
                s_selectedBookCoverImage(cover.selectedImageUrl);
            } else {
                s_selectedBookCoverImage(data.frontCoverData.selectedImageUrl);
            }
        }
    };

    useEffect(() => {
        prepareBookData();
        if (bookId) {
            getConfig(lang, bookData.client?._id).then(data => {
                s_config(data);
            }).catch(e => {
                console.log("e", e);
            });
        }
    }, []);

    const getBookStatus = async (bookId) => {
        let polling = true;
        let numberOfPagesWithImages = 0;
        let howMuchReady = 0;


        const job = await getBookJob(bookId);
        if (job) {
            let refresh = false;
            const tempJobs = jobsStatuses;
            job.forEach((j) => {
                if (!tempJobs[(j.bookPageIndex)?.toString()]?.status) {
                    tempJobs[(j.bookPageIndex).toString()] = {};
                }
                if (j.status === "done") {
                    if (tempJobs[(j.bookPageIndex).toString()].status !== "done") {
                        refresh = true;
                    }
                    if (j.bookPageIndex !== -1) {
                        numberOfPagesWithImages++;
                    }

                    howMuchReady++;
                }
                tempJobs[(j.bookPageIndex).toString()] = {
                    progress: j.mjPercentage, status: j.status,
                };
            });

            if (howMuchReady === prebookPages.length) {
                polling = false;
            }

            if (refresh) {
                setTimeout(() => {
                    getMoreBookData(bookId);
                }, 10000);
            }

            s_jobsStatuses(tempJobs);
            s_imageDataLen(numberOfPagesWithImages);
            s_covList([1, 2, 3, 4]);
        }

        await new Promise(resolve => setTimeout(resolve, 4000));
        if (polling) {
            return getBookStatus(bookId);
        }
    };

    const openPreviewFunc = (e, isClose) => {
        if (isClose) {
            s_closePreviewBookModalForce(true);
        } else {
            s_closePreviewBookModalForce(false);
        }

        setTimeout(() => {
            s_openPreviewBookModal(!openPreviewBookModal);
            s_payView(0);
            s_closePreviewBookModalForce(false);
        }, 200);
    };

    const handleImageChange = (val, idx) => {
        const tempImageData = [...imagesData];
        tempImageData[idx] = val;
        s_imagesData(tempImageData);
    };

    const payActionHandle = () => {
        openPreviewFunc();
    };

    const chooseBookHandle = async (bookType, selectedBook) => {
        try {
            s_loadingButtons(`makingCover_${ bookType }`);
            const bookId = bookData._id;

            const coverDataFromBackend = await createCover(bookId, selectedBookCoverImage, templateTitle, lang);
            const coverUrl = coverDataFromBackend?.data?.url?.replace("cdn.mebook.ai", "print.mebook.ai");

            if (window.self !== window.top) {
                const message = {
                    type: "BOOK_FRAME",
                    bookId: bookId,
                    coverImageUrl: coverUrl,
                    bookType: bookType.toUpperCase(),
                    email: bookData.endUserEmail,
                    lang: lang
                };

                window.parent.postMessage(message, "*");
            } else {
                const paymentPageUrl = config.paymentPageUrl;
                if (paymentPageUrl) {
                    const { params, urlParser } = getParams(paymentPageUrl);
                    params.bookId = bookId;
                    params.bookType = bookType.toUpperCase() || "";
                    params.coverImageUrl = coverUrl || "";
                    params.email = bookData.endUserEmail || "";
                    params.mebook_email = bookData.endUserEmail || "";
                    params.type = "BOOK_FRAME";
                    params.lang = lang;
                    params.mebook_id = bookId;
                    params.mebook_type = bookType.toUpperCase() || "";
                    params.mebook_phone = bookData.endUserPhone || "";

                    const newUrl = createNewUrl(urlParser.origin + urlParser.pathname, params);
                    window.location.href = newUrl;
                } else {
                    const url = `${ PAYMENT_URL }/?mebook_id=${ bookId }&mebook_type=${ bookType.toUpperCase() }&mebook_email=${ bookData.endUserEmail }&coverImageUrl=${ coverUrl }&mebook_phone=${ bookData.endUserPhone }&lang=${ lang }`;
                    window.location.href = url;
                }
            }
        } catch (e) {
            console.log("error", e);
        }
    };

    const getMoreBookData = async (bookId) => {
        const data = await getBook(bookId);
        s_bookData(data);
        prepareBookData(data);
        let numberOfPagesWithImages = 0;
        data?.bookPagesData?.forEach(pp => {
            if (pp.images?.length) {
                numberOfPagesWithImages++;
            }
        });
        if (data?.frontCoverData?.images?.length) {
            numberOfPagesWithImages++;
        }
        s_imageDataLen(numberOfPagesWithImages);
    };

    const gender = bookData?.inputData?.gender;
    const templateTitle = gender === "female" ? bookData?.template?.titleFemale : bookData?.template?.titleMale;
    const coverDesign = bookData?.template?.coverDesign;

    const bookPagesAreReady = textData?.length > 0;

    let bottomPos = 0;
    if (parentData) {
        let pos = 0;
        if (parentData.distanceIframeToBottom < 0) {
            pos = -parentData.distanceIframeToBottom;
        }
        bottomPos = pos;
    }

    const showCover = prebookPages.indexOf(-1) !== -1;

    const showPrebooks = recentPrebooks?.length > 0;

    return (<>
        <EditorPageStyled>

            { noBookId && (<NoBookIdStyled>
                No book id
            </NoBookIdStyled>) }

            { showData && (<PagesWrapperStyled
                ref={ wrapperRef }
            >
                { showPrebooks && (<RecentPrebooks
                    lang={ lang }
                    langData={ langData }
                    items={ recentPrebooks }
                />) }
                <h1>{ langData.choose_cover }</h1>
                { showCover && (<CoverPicsStyled className="coverPictures" ref={ coversRef }>
                    { covList.map((index) => {
                        let isPreview = false;
                        let imageUrl = bookData?.frontCoverData?.[`us_u${ index }`];
                        if (!imageUrl) {
                            imageUrl = bookData.frontCoverData?.images?.[index];
                        }

                        if (!imageUrl) {
                            isPreview = true;
                        }
                        const transformedImage = transformImage(imageUrl, "w_1500");
                        const isSelected = selectedBookCoverImage === imageUrl;

                        let classes = "coverImage bookIsReady";
                        if (isSelected && !isPreview) {
                            classes += " selected";
                        }
                        if (isPreview) {
                            classes += " preview";
                        }

                        return (<ReadyImagesCoverStyled
                            tabIndex="0"
                            role="button"
                            aria-label={ `${ langData.arias.cover_selection } ${ index }` }
                            key={ index }
                            onClick={ (e) => {
                                e.stopPropagation();
                                s_selectedBookCoverImage(imageUrl);
                                return false;
                            } }
                            className={ classes }>
                            { isPreview && (<CoverMediaLoader
                                progress={ bookStatus["-1"]?.progress }
                                position={ bookStatus["-1"]?.position }
                                langData={ langData }
                                text={ langData.more_data_prepearing }
                                contWidth={ height > 500 ? 250 : null }
                            />) }
                            { !isPreview && (<>
                                <BookCoverPrev
                                    wrapperWidth={ coversRef?.current?.offsetWidth || 0 }
                                    isSelected={ isSelected }
                                    index={ index }
                                    imageUrl={ transformedImage }
                                    lang={ lang }
                                    coverDesign={ coverDesign }
                                    text={ templateTitle }
                                    contWidth={ height > 500 ? 250 : null }
                                />
                                <PoloirzsddStyled index={ index }>
                                    <BookCoverPrev
                                        isForCapture={ true }
                                        isSelected={ isSelected }
                                        index={ index }
                                        imageUrl={ transformedImage }
                                        lang={ lang }
                                        coverDesign={ coverDesign }
                                        text={ templateTitle }
                                    />
                                </PoloirzsddStyled>
                            </>) }
                        </ReadyImagesCoverStyled>);
                    }) }
                </CoverPicsStyled>) }
                <GeneraeMoreCoversStyled/>

                { bookPagesAreReady && bookData?.bookPagesData?.map((bookPage, idx) => {
                    return (<PageItemEdit
                        progress={ bookStatus?.[idx.toString()]?.progress }
                        bookPosition={ bookStatus?.[idx.toString()]?.position }
                        isPreviewPage={ true }
                        isPreview={ true }
                        isPreviewLoad={ prebookPages.indexOf(idx) !== -1 }
                        langData={ langData }
                        key={ idx + bookPage._id }
                        item={ bookPage }
                        idxxx={ idx }
                        reset={ () => {
                        } }
                        textData={ textData[idx] }
                        imagesData={ imagesData[idx] }
                        handleTextChange={ val => {
                        } }
                        handleImageChange={ val => handleImageChange(val, idx) }
                        payActionHandle={ payActionHandle }
                        regenerationsLeft={ 0 }
                    />);
                }) }
            </PagesWrapperStyled>) }
            { loading && (<Loader size={ 200 }/>) }

            { openPreviewBookModal && (<Modal
                role="alertdialog"
                ariaTitle={ "תצוגה מקדימה של הספר וסיום" }
                handleClose={ openPreviewFunc }
                isOpen={ true }
                onClose={ openPreviewFunc }
                lang={ lang }
                closeModal={ closePreviewBookModalForce }
                style={ { overflow: "hidden" } }
            >
                <div
                    style={ { width: "100%", position: "relative", overflow: "hidden" } }
                >
                    <AnimatePresence mode="wait">

                        <motion.div
                            key={ payView } // needed for animate presence
                            initial={ { x: 50, opacity: 0 } }
                            exit={ { x: -50, opacity: 0 } }
                            animate={ { x: 0, opacity: 1 } }
                            transition={ { type: "spring", duration: 0.4 } }
                            style={ { overflow: "hidden", position: "relative" } }
                        >
                            { payView === 0 && (<ValidationPopup langData={ langData } lang={ lang }
                                                                 onClose={ (e) => {
                                                                     openPreviewFunc(e, true);
                                                                 } }
                                                                 selectedCoverImage={ selectedBookCoverImage }
                                                                 onNext={ () => {
                                                                     s_payView(1);
                                                                 } }/>) }
                            { payView === 1 && (<PreviewBookAndPay
                                selectedBookCoverImage={ selectedBookCoverImage }
                                bookData={ bookData }
                                lang={ lang }
                                langData={ langData }
                                textData={ textData.slice(0, imageDataLen -1) }
                                imagesData={ imagesData.slice(0, imageDataLen -1) }
                                chooseBookHandle={ chooseBookHandle }
                                loading={ loadingButtons }
                            />) }


                        </motion.div>
                    </AnimatePresence>
                </div>
            </Modal>) }
        </EditorPageStyled>
        { showButtonPay && (<PreviewActionsStyled
            style={ { bottom: bottomPos } }
        >
            <ButtonStyledV2
                aria-busy={ "" }
                aria-label={ langData.to_buy }
                className={ "fire-button-sz" }
                onClick={ payActionHandle }
                style={ { margin: "auto" } }>
                            <span>
                                { langData.to_buy }
                            </span>
            </ButtonStyledV2>
        </PreviewActionsStyled>) }
    </>);
};

export default PreviewPage;
